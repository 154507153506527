import React from 'react';
import Header from './Header.js';
import aws from './aws.png';
import smilefood from './smilefood.png';
import amazon from './amazon.png';
import michigan from './michigan.png';
import bombLit from './BombLit.svg';
import './Experience.css';

class Experience extends React.Component {

  render() {
    return (
      <div className="Experience-Wrapper">
        <Header selected="Experience" backgroundColor="var(--lavender)"/>
        <div className="Jobs-Wrapper">
          <div className="Job">
            <img className="Job-Logo" src={aws} alt="aws" style={{top: "70px"}}/>
            <div className="Job-Description" style={{top: "-20px"}}>
              <p className="Job-Title">Software Engineer</p>
              <p className="Job-Duration">Jun 2021 - present</p>
            </div>
          </div>
          <div className="Job">
            <img className="Job-Logo" src={smilefood} alt="smilefoods"/>
            <div className="Job-Description">
              <p className="Job-Title">iOS Developer</p>
              <p className="Job-Duration">Nov 2022 - Mar 2023</p>
            </div>
          </div>
          <div className="Job">
            <img className="Job-Logo" src={amazon} alt="amazon"/>
            <div className="Job-Description">
              <p className="Job-Title">SWE Intern</p>
              <p className="Job-Duration">Jun 2020 - Aug 2020</p>
            </div>
          </div>
          <div className="Job">
            <img className="Job-Logo" src={michigan} alt="michigan"/>
            <div className="Job-Description">
              <p className="Job-Title">UX Designer</p>
              <p className="Job-Duration">Jan 2019 - Mar 2020</p>
            </div>
          </div>
          <div className="Job" style={{background: "var(--lavender)", "display": "flex", "justify-content": "center"}}>
            <img className="Bomb" src={bombLit} alt="bomb" />
          </div>
        </div>
      </div>
    );
  }
}

export default Experience;
