import React from 'react';
import Header from './Header.js';
import Colors from './Colors.js';
import balloon from './Balloon.svg';
import github from './github.png';
import linkedin from './linkedin.png';
import clouds from './clouds.svg';
import { gsap } from 'gsap';
import './About.css';


/*  TODOs:
- Fix overflow for mobile devices
- Add page transition
*/
class About extends React.Component {
  componentDidMount() {
    document.querySelector('meta[name="theme-color"]').setAttribute("content", Colors.white);
    /*gsap.to(".Screen-Bottom", {
      y: () => window.innerWidth,
      duration: 5,
      ease: "power1"
    });
    gsap.to(".Screen-Top", {
      y: () => -1 * window.innerWidth,
      duration: 5,
      ease: "power1"
    });*/
    gsap.fromTo(".About-Text-Wrapper", {
      y: 1500,
    },
    {
      y: 0,
      duration: 4,
      ease: "power1"
    });
    gsap.fromTo(".Balloon", {
      y: 1500,
    },
    {
      y: 0,
      duration: 4,
      ease: "power1"
    });
    gsap.to(".Outer-Ring", {
      width: "104%",
      height: "104%",
      left: "-2%",
      duration: 2,
      repeat: -1,
      yoyo: true
    });
    gsap.to(".Clouds", {
      y: "5",
      ease: "circ.inout",
      duration: 2.5,
      repeat: -1,
      yoyo: true
    });
  }
  /*  Add under "About-Wrapper"
  <div className="Screen-Top"></div>
  <div className="Screen-Bottom"></div>
  */
  render() {
    return (
      <div className="About-Wrapper">

        <Header selected="About"/>
        <img
          className="Clouds"
          src={clouds}
          alt="Clouds"
        />
        <div className="Sun-Wrapper">
          <div className="Outer-Ring"></div>
          <div className="Middle-Ring"></div>
          <div className="Inner-Ring"></div>
        </div>
        <img
          className="Balloon"
          src={balloon}
          alt="Hot Air Baloon"
        />
        <div className="About-Text-Wrapper">
          <div className="Banner-Rod">
            <div className="Banner"></div>
          </div>
          <div className="About-Text-Content">
            <p style={{fontSize: "18px"}}>
              I am enamored by computers, colors, and fonts. I use
              these passions to make software, websites, and games. Currently
              working as a software engineer based out of Denver.
            </p>
            <p style={{fontWeight: "bold"}}>
              Connect with me
            </p>
            <div className="Social-Links">
              <a
                className="Social-Link"
                href="https://github.com/bmsheppard"
                target="_blank"
                rel="noreferrer">
                <img
                  src={github}
                  alt="Github Link"
                  style={{width: "100%"}}/>
              </a>
              <a
                className="Social-Link"
                href="https://www.linkedin.com/in/ben-m-sheppard"
                target="_blank"
                rel="noreferrer">
                <img
                  src={linkedin}
                  alt="Linkedin Link"
                  style={{width: "100%"}}/>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
