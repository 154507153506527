import React from 'react';
import Colors from './Colors.js';
import { goTo, makeNoise } from './helpers/Helpers.js';
import computer from './Computer-Shell.svg';
import { gsap } from 'gsap';
import './Landing.css';

const COMPUTER_WIDTH = 300;
var computerAnimation;

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.setView = props.setView;
    this.selectComputer = this.selectComputer.bind(this);
    this.state = {
      transitioning: false
    }
  }
  componentDidMount() {
    document.querySelector('meta[name="theme-color"]').setAttribute("content", Colors.brown);
    computerAnimation = gsap.to("#computer", {
      y: "-30",
      duration: 3,
      ease: "circ.inout",
      repeat: -1,
      yoyo: true,
    });

    gsap.to(".Computer-Shadow", {
      width: COMPUTER_WIDTH - 80,
      duration: 3,
      ease: "circ.inout",
      repeat: -1,
      yoyo: true
    });
  }

  selectComputer() {
    computerAnimation.pause();

    this.setState({transitioning: true});

    gsap.to(".Computer-Shadow", { scale: 0, duration: 0.01 });
    gsap.to("#computer", {
      scale: 6,
      opacity: 0,
      delay: 1,
      duration: 2,
      y: 400,
      onComplete: () => {
        goTo("about", false);
      }
    });
    gsap.to(".Landing-Wrapper", {
      backgroundColor: "black",
      delay: 1,
      duration: 3,
    });
    gsap.to("#greeting", {
      opacity: 0,
      delay: 1,
      duration: 0.25,
    });

    var time = 0;
    var intervalId = 0;
    var canvas = document.getElementById("tv");
    var context = canvas.getContext("2d");
    setInterval(function() {makeNoise(time, intervalId, canvas, context)}, 50);
  }

  render() {
    return (
      <div className="Landing-Wrapper">
        <p id="greeting">Hi I'm Ben!</p>
        <div className="Computer-Wrapper">
          <div id="computer" onClick={this.selectComputer}>
            <canvas className="Screen" id="tv" style={this.state.transitioning ? {width: COMPUTER_WIDTH - 60} : {display: "none"}}></canvas>
            <div className="Screen" style={this.state.transitioning ? {display: "none"} : {width: COMPUTER_WIDTH - 60}}>
              <div className="Screen-Bar" style={{"backgroundColor": Colors.red}}></div>
              <div className="Screen-Bar" style={{"backgroundColor": Colors.orange}}></div>
              <div className="Screen-Bar" style={{"backgroundColor": Colors.yellow}}></div>
              <div className="Screen-Bar" style={{"backgroundColor": Colors.green}}></div>
              <div className="Screen-Bar" style={{"backgroundColor": Colors.blue}}></div>
              <div className="Screen-Bar" style={{"backgroundColor": Colors.turquoise}}></div>
              <div className="Screen-Bar" style={{"backgroundColor": Colors.lavender}}></div>
              <div className="Screen-Bar" style={{"backgroundColor": Colors.purple}}></div>
            </div>
            <img style={{width: COMPUTER_WIDTH, cursor: "pointer"}} src={computer} alt="computer"/>
          </div>
          <div className="Computer-Shadow" style={{width: COMPUTER_WIDTH - 40}}></div>
        </div>
      </div>
    )
  }
}

export default Landing;
