import React from 'react';
import leaf from './Leaf.svg';
import { gsap } from 'gsap';
import './Leaf.css';

class Leaf extends React.Component {
  constructor(props) {
    super(props);
    this.yPos = props.yPos;
    this.text = props.text;
    this.reverse = props.reverse;
  }

  componentDidMount() {
    // x position needs to be factored from the scale
    if (this.reverse) {
      gsap.from(`#Leaf-Wrapper-${this.yPos}`, { scale: 0.1, duration: 0.5, x: -150, rotation: "315_short"})
    }
    else {
      gsap.from(`#Leaf-Wrapper-${this.yPos}`, { scale: 0.1, duration: 0.5, x: -150, rotation: 50})
    }
  }

  render() {
    return (
      // this is probably not necessary. I imagine an exiting animation can be triggered from the projects class
      <div
        id={`Leaf-Wrapper-${this.yPos}`}
        className="Leaf-Wrapper"
        style={this.reverse ? {bottom: `${this.yPos}px` } : {bottom: `${this.yPos}px` ,transform: "rotateY(180deg) translateX(250px)"}}>
        {this.reverse ?
          <img src={leaf} alt="leaf" width="100%" style={{rotate: "10deg"}}/>
          :
          <img src={leaf} alt="leaf" width="100%" style={{rotate: "10deg"}}/>
        }
      </div>
    );
  }
}

export default Leaf;
