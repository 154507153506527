import React from 'react';
import flower from './flower.svg';
import { gsap } from 'gsap';
import './Flower.css';

class Flower extends React.Component {
  constructor(props) {
    super(props);
    this.yPos = props.yPos;
    this.text = props.text;
    this.link = props.link;
  }

  componentDidMount() {
    // x position needs to be factored from the scale
    var t1 = gsap.timeline();
    t1.from(`#Flower-Center-${this.yPos}`, { scale: 0.1, duration: 0.3});
    t1.to(`#Flower-Petals-${this.yPos}`, { scale: 1.1, delay: 0.2, duration: 1, repeat: -1, yoyo: true,});

    gsap.from(`#Flower-Petals-${this.yPos}`, { scale: 0.1, duration: 0.5});

  }

  render() {
    return (
      <a href={this.link} target="_blank" rel="noreferrer">
        <div
          id={`Flower-Wrapper-${this.yPos}`}
          className="Flower-Wrapper"
          style={{bottom: `${this.yPos}px`}}>
          {
            <img
              key={this.yPos}
              className="Flower-Petals"
              id={`Flower-Petals-${this.yPos}`}
              src={flower}
              alt="Flower"
              style={{bottom:`${this.yPos}px`}}/>
          }
          <div
            className="Flower-Center"
            id={`Flower-Center-${this.yPos}`}
            style={{bottom: `${this.yPos+150}px`
          }}>
            <p>{this.text}</p>
          </div>
        </div>
      </a>
    );
  }
}

export default Flower;
