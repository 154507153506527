import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from './Landing.js';
import About from './About.js';
import Projects from './Projects.js';
import Experience from './Experience.js';

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/about' element={<About />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/experience' element={<Experience />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
