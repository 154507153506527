import React from 'react';
import { goTo } from './helpers/Helpers.js';
import home from './home.svg';
import './Header.css';

/* TODOs:
- Add Logo
*/
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.selected = props.selected;
    this.backgroundColor = props.backgroundColor;
  }

  render() {
    return (
      <div className="Header-Wrapper" style={this.backgroundColor ? {backgroundColor: this.backgroundColor, color: "var(--white)"} : {}}>
        <div
          className={"Header-Item"}
          style={this.selected === "About" ? {"fontWeight": "bold"} : {}}
          onClick={() => goTo("/about", false)}>
          About
        </div>
        <img
          className="Header-Item"
          style={{ width: 30 }}
          src={home}
          alt="Home Button"
          onClick={() => goTo("/", false)}
        />
        <div
          className="Header-Item"
          style={this.selected === "Projects" ? {"fontWeight": "bold"} : {}}
          onClick={() => goTo("/projects", false)}>
          Projects
        </div>
      </div>
    );
  }
}

/*
<div
  className="Header-Item"
  style={this.selected === "Experience" ? {"fontWeight": "bold"} : {}}
  onClick={() => goTo("/experience", false)}>
  Experience
</div>
*/

export default Header;
