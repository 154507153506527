const Colors = {
  black: "#000000",
  blue: "#75B9BE",
  brown: "#704E2E",
  green: "#9BC53D",
  lavender: "#6369D1",
  orange: "#E28413",
  purple: "#330036",
  red: "#F45B69",
  turquoise: "#0892A5",
  yellow: "#FFAE03",
  white: "#E0E0E0",
}

export default Colors;
