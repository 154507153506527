import React from 'react';
import Header from './Header.js';
import Leaf from './Leaf.js';
import Flower from './Flower.js';
import Colors from './Colors.js';
import pot from './pot.svg';
import { gsap } from 'gsap';
import './Projects.css';

const MAX_PLANT_HEIGHT = 2500;

/*
TODOs:
  - add project info popup
  - add page transition
*/
class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plantHeight: 10,
      activeLeafs: [],
      activeFlowers: [],
      deletingLeaf: false,
      deletingFlower: false,
    }
    this.inactiveLeafs = [200,750,1350,1850];
    this.inactiveFlowers = [450,1000,1600,2200];
    this.projectNames = ["Leftovers", "Flight", "Stability", "Values Finder"];
    this.projectLinks = ["https://leftovers-orb.netlify.app/", "https://flight-orb.netlify.app/", "https://github.com/b-sheppard/Stability", "https://valuesselector.netlify.app/"]
    this.grow = this.grow.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    document.querySelector('meta[name="theme-color"]').setAttribute("content", Colors.purple);
    window.addEventListener('wheel', this.handleScroll);
    var curY = 0;
    window.addEventListener('touchstart', function (e) {
       curY = e.touches[0].clientY;
    });
    window.addEventListener('touchmove', (e) => {
      this.grow(-0.75*(e.touches[0].clientY - curY));
      curY = e.touches[0].clientY;
    });
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.handleScroll);
  }

  handleScroll(event) {
    let scrollMultiplier = 0.15;
    this.grow(event.deltaY * scrollMultiplier);
  }

  grow(newHeight) {
    let plantHeight= Math.max(this.state.plantHeight + newHeight, 10);
    if (this.state.plantHeight > MAX_PLANT_HEIGHT && newHeight > 0)  {
      return
    }
    var activeFlowers = this.state.activeFlowers;
    // add flower
    if (this.inactiveFlowers.length > 0 && this.inactiveFlowers[0] + 200 < plantHeight) {
      let flowerHeight = this.inactiveFlowers.shift();
      activeFlowers.push(<Flower key={flowerHeight}
        yPos={flowerHeight}
        text={this.projectNames[activeFlowers.length]}
        link={this.projectLinks[activeFlowers.length]}/>
      );
    }
    // remove flower
    if (!this.state.deletingFlower && this.state.activeFlowers.length > 0 && parseInt(this.state.activeFlowers[this.state.activeFlowers.length - 1].key) + 200 > plantHeight) {
      this.setState({deletingFlower: true})
      let yPos = parseInt(activeFlowers[activeFlowers.length - 1].key);
      gsap.to(`#Flower-Center-${yPos}`, { scale: 0, duration: 0.4 });
      gsap.to(`#Flower-Petals-${yPos}`, { scale: 0, duration: 0.3, onComplete: () => {
        let flower = activeFlowers.pop();
        this.inactiveFlowers.unshift(parseInt(flower.key));
        this.setState({
          activeFlowers: activeFlowers,
          deletingFlower: false
        })
      }})
    }
    var activeLeafs = this.state.activeLeafs;
    // add leaf
    if (this.inactiveLeafs.length > 0 && this.inactiveLeafs[0] + 50 <= plantHeight) {
      let leafHeight = this.inactiveLeafs.shift();
      activeLeafs.push(<Leaf
        key={leafHeight}
        yPos={leafHeight + 200}
        text={leafHeight}
        reverse={activeLeafs.length % 2}
      />)
    }
    // remove leaf
    if (!this.state.deletingLeaf && this.state.activeLeafs.length > 0 && parseInt(this.state.activeLeafs[this.state.activeLeafs.length - 1].key) + 50 > plantHeight) {
      this.setState({deletingLeaf: true})
      let yPos = parseInt(activeLeafs[activeLeafs.length - 1].key) + 200;
      if (this.state.activeLeafs.length % 2) {
        gsap.to(`#Leaf-Wrapper-${yPos}`, { scale: 0, duration: 0.35, x: -150, rotation: 50, onComplete: () => {
          let leaf = activeLeafs.pop();
          this.inactiveLeafs.unshift(parseInt(leaf.key));
          this.setState({
            activeLeafs: activeLeafs,
            deletingLeaf: false
          });
        }})
      }
      else {
        gsap.to(`#Leaf-Wrapper-${yPos}`, { opacity: 0, scale: 0, duration: 0.35, x: -150, rotation: "315_short", onComplete: () => {
          let leaf = activeLeafs.pop();
          this.inactiveLeafs.unshift(parseInt(leaf.key));
          this.setState({
            activeLeafs: activeLeafs,
            deletingLeaf: false
          });
        }})
      }
    }
    this.setState({
      plantHeight: plantHeight,
      activeLeafs: activeLeafs,
      activeFlowers: activeFlowers
    });
  }

  render() {
    return (
      <div className="Projects-Wrapper">
        <Header selected="Projects" backgroundColor="var(--purple)"/>
        <div
          className="Plant-Wrapper"
          style={{height: `${350 + this.state.plantHeight}px`}}>
          <div className="Leaf-Container" style={{position: "relative"}}>
            {
              this.state.activeLeafs
            }
          </div>
          <div className="Flower-Container" style={{position: "relative", "zIndex": "5"}}>
            {
              this.state.activeFlowers
            }
          </div>
          <div style={{height: "30px", width: "100vw"}}></div>
          <img
            className="Pot"
            src={pot}
            alt="Pot"/>
          <div
            className="Stem"
            style={{height: `${this.state.plantHeight}px`}}>
              <div
                style={
                  {
                    background: "black",
                    opacity: "0.2",
                    height: "100%",
                    width: "12px"
                  }}>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
